//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";

export default {
  name: "InfoPanel",

  computed: {
    ...mapState({
      currentItem: state => {
        return state.monuments.selectedItem;
      }
    })
  },

  methods: {
    formatGPSCoord(number) {
      return parseFloat(number).toFixed(6);
    },
    openPhotoDatesDialog() {
      if (this.currentItem && this.currentItem.images.length > 0) this.$store.dispatch("monuments/toggleAlbumDatesDialog");
    },
  },

  components: {}
};
