//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Carousel",

  data() {
    return {
      slide: 1,
      fullscreen: false,
      virtualListIndex: 0,
    }
  },

  computed: {
    photoGalleryDialog: {
      get() {
        return this.$store.state.monuments.albumPhotoGalleryDialog;
      },
      set: function () {
        this.$store.dispatch("monuments/toggleAlbumPhotoGalleryDialog");
      }
    },

    photoAlbums() {
      return this.$store.getters["monuments/getSelectedItemPhotoAlbums"];
    },

    currentSectionIndex() {
      return this.$store.state.monuments.selectedItem.albumSectionIndex;
    },

    currentAlbumSection() {
      const returnItem = this.photoAlbums.filter(item => item.date === this.$store.state.monuments.selectedItem.albumDate)[0].sections[this.currentSectionIndex];
      // console.log('@Carousel.vue :: @currentAlbumSection >> ', returnItem);
      return returnItem;
    },
  },

  methods: {
    imgCardClick(element, cardIndex) {
      // console.log('previous element: ', this.$refs[`imgStrip_${this.virtualListIndex}`].classList);
      // this.$refs[`imgStrip_${this.virtualListIndex}`].classList.remove('img-strip-card-selected');
      // element.classList.add('img-strip-card-selected');
      this.virtualListIndex = cardIndex;
      this.$refs.thumbnailsScrollArea.scrollTo(cardIndex);
    },

    virtualScrollUpdatePosition({index}) {
      this.virtualListIndex = index
      // console.log('index: ', index);
    },

    scrollLeft() {
      this.virtualListIndex = this.virtualListIndex > 0 ? this.virtualListIndex - 1 : this.virtualListIndex;
      this.$refs.thumbnailsScrollArea.scrollTo(this.virtualListIndex);
      // console.log('virtualListIndex = ', this.virtualListIndex);
    },

    scrollRight() {
      this.virtualListIndex = this.virtualListIndex < this.currentAlbumSection.thumbnails.length - 1 ? this.virtualListIndex + 1 : this.virtualListIndex;
      this.$refs.thumbnailsScrollArea.scrollTo(this.virtualListIndex);
      // console.log('virtualListIndex = ', this.virtualListIndex);
    },

    // imgStripHandleSwipe({evt, ...info}) {
    //   this.info = info
    //
    //   // native Javascript event
    //   // console.log(evt)
    // },

  },

}
