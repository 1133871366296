//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchPanel from '@/components/SearchPanel';
import InfoPanel from '@/components/InfoPanel';
import AlbumDates from './components/AlbumDates';
import AlbumSections from './components/AlbumSections';
import AlbumPhotoGallery from './components/AlbumPhotoGallery';
import {mapState} from 'vuex';

export default {
  name: "App",

  data() {
    return {};
  },

  components: {
    SearchPanel,
    InfoPanel,
    AlbumDates,
    AlbumSections,
    AlbumPhotoGallery,
  },

  computed: {
    // if home route, hide all bars and panels
    isHomeRoute() {
      return this.$route.name === 'home';
    },
    leftPanel: {
      get() {
        // console.log('app: getLeftPanel');
        return this.$store.state.monuments.leftPanel;
      }
      ,
      set: function (value) {
        // console.log('app: setLeftPanel: ', value);
        this.$store.dispatch('monuments/setLeftPanel', value);
      },
    },
    rightPanel: {
      get() {
        // console.log('app: getRightPanel');
        return this.$store.state.monuments.rightPanel;
      },
      set: function (value) {
        // console.log('app: setRightPanel: ', value);
        this.$store.dispatch('monuments/setRightPanel', value);
      },
    },
    monumentInfoShown: {
      get() {
        return (
            this.$store.state.monuments.monumentDisplayed &&
            this.$store.state.monuments.selectedItem &&
            !!this.$store.state.monuments.selectedItem['cod_lmi']
        );
      },
      set: function (value) {
        // console.log('app: setMonumentInfoShown: ', value);
        this.$store.dispatch('monuments/setRightPanel', value);
      },
    },
    ...mapState({
      monumentDisplayed: (state) => state.monuments.monumentDisplayed,
    }),
  },

  methods: {
    toggleLeftPanel() {
      this.$store.dispatch('monuments/toggleLeftPanel');
    },
    toggleRightPanel() {
      this.$store.dispatch('monuments/toggleRightPanel');
    },
    clearSelection: function () {
      this.toggleRightPanel();
      this.$store.dispatch('monuments/clearSelection');
    },
  },
  created: async function () {
    // get monuments list
    await this.$store.dispatch('monuments/getAllMonuments');

    // get monuments photos
    this.$store.dispatch('photos/getMonumentImages', this.$store.state.photos.monumentShown.nr);

    // open the left panel
    if (this.$q.platform.is.desktop) this.leftPanel = true;
  },
};
