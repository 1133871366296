//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {

  data () {
    return {
      colorCodes: {
        REPREZENTATIV: 'purple-9',
        DOMESTIC_URBAN: 'orange-8',
        DOMESTIC_RURAL: 'red-8',
        RELIGIOS: 'blue-7',
        ADMINISTRATIV: 'yellow-12',
        TRANSPORTURI: 'purple-12',
        PREINDUSTRIAL_PRELUCRATOR: 'lime-13',
        INDUSTRIAL_EXTRACTIV: 'light-green-13',
        INDUSTRIAL_PRELUCRATOR: 'cyan-12',
        INDUSTRIAL_ENERGETIC: 'green-10',
      },
    };
  },
  computed: {
    filterText: {
      get () {
        return this.$store.state.monuments.filterText || "";
      },
      set: function (value) {
        this.$store.dispatch("monuments/setFilterText", value);
      }
    },
    filtruTipPatrimoniu: {
      get (){
        return this.$store.state.monuments.filtruTipPatrimoniu || "";
      },
      set (value) {
        this.$store.dispatch("monuments/setFiltruTipPatrimoniu", value);
      }
    },
    filtruValoareMon: {
      get (){
        return this.$store.state.monuments.filtruValoareMon || "";
      },
      set (value) {
        this.$store.dispatch("monuments/setFiltruValoareMon", value);
      }
    },
    filtruScaraMon: {
      get (){
        return this.$store.state.monuments.filtruScaraMon || "";
      },
      set (value) {
        this.$store.dispatch("monuments/setFiltruScaraMon", value);
      }
    },
    
    monFilteredList () {
      return this.$store.getters["monuments/filteredArray"];
    },
    countFilteredList () {
      return this.monFilteredList.length;
    },
    getColorCode (value) {
      return this.colorCodes[value];
    }
  },
  methods: {
    selectMonument (codLmi) {
      this.$store.dispatch("monuments/selectItem", codLmi);
      if(this.$q.platform.is.mobile) this.$store.dispatch('monuments/setLeftPanel', false);
    }
  }
};
