import { render, staticRenderFns } from "./AlbumDates.vue?vue&type=template&id=6969a42f&scoped=true&"
import script from "./AlbumDates.vue?vue&type=script&lang=js&"
export * from "./AlbumDates.vue?vue&type=script&lang=js&"
import style0 from "./AlbumDates.vue?vue&type=style&index=0&id=6969a42f&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6969a42f",
  null
  
)

export default component.exports
import {QDialog,QLayout,QHeader,QBtnGroup,QBtn,QPageContainer,QResponsive,QCard,QImg,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QLayout,QHeader,QBtnGroup,QBtn,QPageContainer,QResponsive,QCard,QImg})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
